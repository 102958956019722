import React, { useState, useEffect } from 'react';

const questions = [
  {
    question: "What does IaaS stand for?",
    options: ["Internet as a Service", "Infrastructure as a Service", "Integration as a Service", "Information as a Service"],
    correct: 1
  },
  {
    question: "Which service model provides a platform for developers to build and run applications without managing the underlying infrastructure?",
    options: ["SaaS", "PaaS", "IaaS", "FaaS"],
    correct: 1
  },
  {
    question: "What is the term for the practice of using multiple cloud services from different providers?",
    options: ["Cloud Hopping", "Multi-Cloud", "Cloud Fusion", "Hybrid Cloud"],
    correct: 1
  },
  {
    question: "Which cloud deployment model keeps all resources on-premises?",
    options: ["Public Cloud", "Private Cloud", "Hybrid Cloud", "Community Cloud"],
    correct: 1
  },
  {
    question: "What does API stand for in cloud computing?",
    options: ["Application Programming Interface", "Advanced Protocol Integration", "Automated Process Interaction", "Application Process Interpreter"],
    correct: 0
  }
];

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [timer, setTimer] = useState(900); // 15 minutes in seconds

  useEffect(() => {
    if (timer > 0 && !showResult) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setShowResult(true);
    }
  }, [timer, showResult]);

  const handleAnswer = (selectedOption) => {
    if (selectedOption === questions[currentQuestion].correct) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResult(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
    setTimer(900);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (showResult) {
    return (
      <div className="max-w-md mx-auto mt-10 bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <p className="text-xl mb-2">Your score: {score} out of {questions.length}</p>
          <p className="mb-4">Time taken: {formatTime(900 - timer)}</p>
          <button 
            onClick={restartQuiz}
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Restart Quiz
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-10 bg-white shadow-md rounded-lg overflow-hidden">
      <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Cloud Computing Quiz</h2>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(currentQuestion / questions.length) * 100}%` }}></div>
        </div>
        <p className="text-lg mb-4">Time remaining: {formatTime(timer)}</p>
        <p className="text-xl mb-4">{questions[currentQuestion].question}</p>
        {questions[currentQuestion].options.map((option, index) => (
          <button
            key={index}
            onClick={() => handleAnswer(index)}
            className="w-full mb-2 p-2 text-left bg-gray-100 hover:bg-gray-200 rounded transition duration-200"
          >
            {option}
          </button>
        ))}
        <p className="text-sm mt-4">Question {currentQuestion + 1} of {questions.length}</p>
      </div>
    </div>
  );
}

export default App;